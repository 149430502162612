<template>
  <div :class="[$style.component, { [$style.error]: error }]">
    <div :class="$style.component__title">
      <slot></slot>
    </div>
    <q-editor
      v-model="editorValue"
      :toolbar="toolbar"
      min-height="100px"
    ></q-editor>
  </div>
</template>
<script>
  import { defineComponent, computed } from 'vue';

  export default defineComponent({
    props: {
      modelValue: {
        type: String,
      },
      error: {
        type: Boolean,
      },
      toolbar: {
        type: Array,
        default: () => [['left', 'center', 'right', 'justify'], ['bold', 'italic', 'strike', 'underline'], ['undo', 'redo']],
      },
    },
    setup(props, { emit }) {
      const editorValue = computed({
        get() {
          return props.modelValue;
        },
        set(payload) {
          emit('update:model-value', payload);
        },
      });
      return {
        editorValue,
      };
    },
  });
</script>
<style lang="scss" module>
  @import '~@/styles/quasar.variables';

  .component {
    border: 2px solid transparent;
    border-radius: 4px;

    &__title {
      padding: 8px 8px 4px 4px;
    }
  }
  .error {
    border: 2px solid $negative;

    .component {
      &__title {
        color: $negative;
      }
    }
    :global .q-editor {
      border-color: transparent;
    }
  }
</style>
