<template>
  <div :class="$style.component">
    <slot
      :open="open"
      name="activator"
    ></slot>
    <q-dialog
      v-model="dialog"
      :full-height="fullHeight"
      @show="onDialogShow"
      @hide="onDialogHide"
    >
      <q-card
        :style="{ maxWidth }"
        class="full-width flex column no-wrap"
      >
        <q-card-section>
          <slot name="title"></slot>
          <q-btn
            v-close-popup
            :class="$style.component__close"
            flat
            round
            dense
            icon="close"
          ></q-btn>
        </q-card-section>
        <q-separator></q-separator>
        <q-card-section :class="['scroll', $style.component__content]">
          <slot name="content"></slot>
        </q-card-section>
        <q-separator></q-separator>
        <q-card-actions>
          <slot
            :close="close"
            name="actions"
          ></slot>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
  import { defineComponent, ref } from 'vue';

  export default defineComponent({
    props: {
      fullHeight: {
        type: Boolean,
        default: false,
      },
      maxWidth: {
        type: String,
        default: '1200px',
      },
    },
    setup(props, { emit }) {
      const dialog = ref(false);
      const open = () => {
        dialog.value = true;
      };
      const close = () => {
        dialog.value = false;
      };
      return {
        dialog,
        open,
        close,
        onDialogHide() {
          emit('hide');
        },
        onDialogShow() {
          emit('show');
        },
      };
    },
  });
</script>
<style lang="scss" module>
  .component {
    &__close {
      position: absolute;
      top: 16px;
      right: 16px;
    }
    &__content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }
</style>
