<template>
  <q-select
    v-model="model"
    :class="$style.component"
    :error="error"
    :error-message="errorMessage"
    :options="options"
    :label="label"
    :hide-bottom-space="hideBottomSpace"
    :multiple="multiple"
    :use-chips="multiple"
    :clearable="clearable"
    :option-label="optionLabel"
    option-value="id"
    outlined
    dense
    ref="select"
  ></q-select>
</template>
<script>
  import { computed, defineComponent, nextTick, onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import i18n from '@/i18n';
  import DictionariesStore from '@/store/modules/dictionaries.store';

  export default defineComponent({
    props: {
      modelValue: {
        type: [Array, Number],
      },
      regionOptions: {
        type: Array,
      },
      error: {
        type: Boolean,
        default: false,
      },
      errorMessage: {
        type: String,
      },
      label: {
        type: String,
      },
      hideBottomSpace: {
        type: Boolean,
        default: false,
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      clearable: {
        type: Boolean,
        default: false,
      },
    },
    setup(props, { emit }) {
      const { state, dispatch } = useStore();
      const select = ref(null);
      const options = computed(() => props.regionOptions || state[`${DictionariesStore.name}`].regions);
      const model = computed({
        get() {
          if (Array.isArray(props.modelValue)) {
            const uniqueModelValue = [...new Set(props.modelValue)];
            return options.value.filter(({ id }) => uniqueModelValue.includes(id));
          }
          return options.value.find(({ id }) => id === props.modelValue);
        },
        async set(payload) {
          const value = Array.isArray(payload) ? payload.map(({ id }) => id) : payload?.id;
          emit('update:model-value', value);
          select.value.updateInputValue('');
          await nextTick();
          select.value.hidePopup();
        },
      });
      onMounted(() => {
        dispatch(`${DictionariesStore.name}/getRegions`);
      });
      return {
        select,
        options,
        model,
        optionLabel(payload) {
          return payload.name[i18n.global.locale];
        },
      };
    },
  });
</script>
<style lang="scss" module>
  .component {
    :global .q-chip {
      margin: 2px 4px 0;
    }
  }
</style>
