<template>
  <q-header
    :class="$style.component"
    class="bg-white text-black"
    bordered
  >
    <q-toolbar class="bg-white q-pa-none">
      <q-btn
        padding="md"
        flat
        dense
        icon="menu"
        @click="onMenuClick"
      ></q-btn>
      <q-separator vertical></q-separator>
      <span class="q-px-md">{{ login }}</span>
      <q-space></q-space>
      <q-separator vertical></q-separator>
      <q-btn
        padding="md"
        :color="autoRefresh ? 'primary' : 'black'"
        :icon="autoRefresh ? 'published_with_changes' : 'sync_disabled'"
        flat
        dense
        @click="onAutoRefreshClick()"
      >
        <q-tooltip>{{ $t('MBO.AUTO_REFRESH') }}</q-tooltip>
      </q-btn>
      <q-separator vertical></q-separator>
      <q-btn
        padding="md"
        flat
        dense
        icon="language"
      >
        <q-menu>
          <q-list :class="$style.component__list">
            <q-item
              v-close-popup
              v-for="(item, index) in languages"
              :key="index"
              clickable
              @click="onLanguageClick(item)"
            >
              <q-item-section>{{ item.originalName }}</q-item-section>
              <q-item-section
                v-if="item.code === currentLanguage.code"
                avatar
              >
                <q-icon name="done"></q-icon>
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </q-toolbar>
  </q-header>
</template>
<script>
  import { computed, defineComponent, inject } from 'vue';
  import { useStore } from 'vuex';
  import LanguagesStore from '@/store/modules/languages.store';
  import SecurityStore from '@/store/modules/security.store';

  export default defineComponent({
    props: {
      modelValue: {
        type: Boolean,
      },
    },
    setup(props, { emit }) {
      const { state, dispatch, getters, commit } = useStore();
      const languages = computed(() => state[LanguagesStore.name].languages);
      const currentLanguage = computed(() => getters[`${LanguagesStore.name}/currentLanguage`]);
      const login = computed(() => getters[`${SecurityStore.name}/login`]);
      const autoRefresh = inject('$autoRefresh');
      return {
        languages,
        currentLanguage,
        login,
        autoRefresh,
        onMenuClick() {
          emit('update:model-value', !props.modelValue);
        },
        async onLanguageClick({ code }) {
          await dispatch(`${LanguagesStore.name}/getRenditions`, code);
          commit(`${LanguagesStore.name}/setCurrentLanguage`, code);
        },
        onAutoRefreshClick() {
          autoRefresh.value = !autoRefresh.value;
        },
      };
    },
  });
</script>
<style lang="scss" module>
  .component {
    &__list {
      min-width: 220px;
    }
  }
</style>
