<template>
  <q-avatar
    :color="color"
    text-color="white"
    size="md"
  >
    <q-icon
      :name="iconName"
      size="sm"
    >
      <q-tooltip>
        <div>{{ $t('MBO.CATEGORY_LABEL') }} {{ $t(`MBO.${category}`) }}</div>
        <div v-if="riskLevel !== RiskLevel.none">
          <span>{{ $t('MBO.RISK_LEVEL_LABEL') }} {{ riskLevel }}</span>
        </div>
      </q-tooltip>
    </q-icon>
  </q-avatar>
</template>
<script>
  import { defineComponent, computed } from 'vue';
  import { CategoryIcon } from '@/common/constants/categories';
  import { RiskLevel, RiskLevelColor } from '@/common/constants/risk-levels';

  export default defineComponent({
    props: {
      category: {
        type: String,
      },
      riskLevel: {
        type: Number,
        default: RiskLevel.none,
      },
    },
    setup(props) {
      return {
        RiskLevel,
        color: computed(() => RiskLevelColor[props.riskLevel]),
        iconName: computed(() => CategoryIcon[props.category]),
      };
    },
  });
</script>
