<template>
  <q-dialog
    v-model="isOpen"
    persistent
  >
    <q-card :style="style">
      <q-card-section>
        <h1
          v-html="title || $t('MBO.ARE_YOU_SURE')"
          class="text-h6"
        ></h1>
      </q-card-section>
      <q-card-section class="q-pt-none">
        <div v-html="content"></div>
      </q-card-section>
      <q-card-actions align="between">
        <q-btn
          :label="$t('MBO.CANCEL')"
          flat
          @click="close"
        >
        </q-btn>
        <q-btn
          :label="$t('MBO.YES')"
          color="primary"
          flat
          @click="agree"
        ></q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
  import { defineComponent, reactive, toRefs } from 'vue';

  export default defineComponent({
    setup() {
      const dialog = reactive({
        isOpen: false,
        style: { width: '500px' },
        title: null,
        content: null,
        resolve: null,
        reject: null,
      });
      return {
        ...toRefs(dialog),
        open({ title, content, style }) {
          dialog.isOpen = true;
          dialog.style = { ...dialog.style, ...style };
          dialog.title = title;
          dialog.content = content;
          return new Promise((resolve, reject) => {
            dialog.resolve = resolve;
            dialog.reject = reject;
          });
        },
        agree() {
          dialog.resolve(true);
          dialog.isOpen = false;
        },
        close() {
          dialog.resolve(null);
          dialog.isOpen = false;
        },
      };
    },
  });
</script>
